
import router from '@/router'
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import jsQR from 'jsqr';
import { notification } from 'ant-design-vue';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import Constains from '@/components/Constains';

export default defineComponent({
  name: "Camera",

  setup() {
    const cookies  = useCookies();

    const video = ref()
    const points:any = ref([])
    const point:any = ref()
    const data = ref('')
    const loading = ref(false)
    const cameramode = ref(false)
    const visible = ref(false)

    const width = ref(300)

    onMounted(async() => {
      point.value = null
      width.value = window.innerWidth * 0.9
      await reload();
    });

    onUnmounted(()=>{
      video.value.pause();
      video.value.srcObject.getVideoTracks().forEach( (camera) => {
        camera.stop();
      });
    })

    const modeChange = () => {
      video.value.pause()
      if(point.value != null) {
        if(video.value.srcObject!== null ){
          video.value.pause();
          video.value.srcObject.getVideoTracks().forEach( (camera) => {
            camera.stop();
          });
        }

        const width = window.innerWidth * 0.9

        const constraints = {
          audio: false,
          video: {
          //width: { min: 512, ideal: 1280, max: 1920 },
          //  width: { min: 238, ideal: 720, max: 1080 },
          //  height: { min: 238, ideal: 720, max: 1080 },
          width: width,
          height: width,
            facingMode: cameramode.value ? "user" : { exact: "environment" },
          }
        };

        navigator.mediaDevices.getUserMedia(constraints)
          .then( (stream) => {
            video.value.srcObject = stream;
            video.value.onloadedmetadata = (e) => {
              video.value.play()
              checkPicture()
            }
          })
          .catch( (err) => {
            notification.error({
                  message: err,
                })
          });
      }
    }

    const reload = async() => {
      var token = cookies.cookies.get('token');
        if(token == null){
          //ログインページに飛ばす
          if(Constains.autoLogout){
            router.push('/login');
          return;
          }
        }

        try{
          let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
            'Authorization' :'Bearer '+token, }});
        if(res.status == 200){
          let result = res.data;
          if(result.activate == 0) {
            if(Constains.autoLogout){
              notification.error({
                message: '非アクティブなアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
          }
         } else if(res.status==400) {
          //ログアウトしてログイン画面に
          if(Constains.autoLogout){
            cookies.cookies.remove('token');
              notification.error({
                message: '無効なアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
         }
        } catch (e) {
          notification.error({
                message: 'ログインに失敗しました.',
              })
              return
        }

      //場所を取得
      const res = await axios.get(Constains.tracerbaseURL+'/api/Point',{headers:{
          'Authorization' :'Bearer '+token,
        }});
        console.log("Point",res)

        if(res.status == 200){
          let results = res.data;
          points.value = []
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            points.value.push({
              value:result['id'],
              label:result['label'],
            });
          }
        } 
    }

    const sound = () => {
      const audioCtx = new window.AudioContext()

      const oscillator = audioCtx.createOscillator()
      oscillator.type = 'sine'
      oscillator.frequency.setValueAtTime(1200, audioCtx.currentTime)
      oscillator.frequency.setValueAtTime(800, audioCtx.currentTime + 0.1)
      oscillator.connect(audioCtx.destination)
      oscillator.start(audioCtx.currentTime)
      oscillator.stop(audioCtx.currentTime + 0.2)
    }

    const post = async() => {
      loading.value = true;
      //dataを投稿
      var token = cookies.cookies.get('token');
      var pointlabel = points.value.filter(e => e.value == point.value)[0].label
      const json = {
        qr:data.value,
        point:pointlabel,
      }

      let res = await axios.post(Constains.tracerbaseURL+'/api/QRDatas',JSON.stringify(json),
        {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
        
      if(res.status == 200||res.status == 201||res.status == 204){
        data.value = ''
        loading.value = false;
        visible.value = false;
        notification.success({
          message:'登録完了',
          //placement:'bottomRight',
        })
      } else {
        data.value = ''
        loading.value = false;
        visible.value = false;
        notification.error({
          message:'登録失敗',
          //placement:'bottomRight',
        })
      }

      cancel();
    }

    const cancel = () => {
      data.value = ''
      visible.value = false
      video.value.play()
            setTimeout( () => {
              checkPicture();
            }, 300);
    }

    const checkPicture = () => {
      if(loading.value) return
      if(visible.value) return

      var canvas = document.createElement("canvas");
      canvas.width = video.value.width;
      canvas.height = video.value.height;
      var context:any = canvas.getContext("2d");
      context.drawImage(video.value, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      console.log(imageData)
      const code = jsQR(imageData.data, canvas.width, canvas.height);
      console.log(code,data.value)
      if(code){
        var bytes = code.binaryData
        var text_decoder = new TextDecoder('shift-jis');
        var str = text_decoder.decode(Uint8Array.from(bytes).buffer);
        data.value = str
        video.value.pause()
        sound()
        visible.value = true
      } else {
        // 0.3秒後にもう一度チェックする
        setTimeout( () => {
          checkPicture();
        }, 300);
      }
    }

    return {
      video,
      data,
      loading,
      post,
      points,
      point,
      cameramode,
      modeChange,
      visible,
      cancel,
      width,
    };
  },
})
